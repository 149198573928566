// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XA7aVRigW", "PhmhcHCGo", "JTogpg1yy", "YW9NwFxdL", "i2VRM5KFs", "IFurfEriY", "dSwoN3Col", "l8r4qk4au", "lHf0A5EYn"];

const serializationHash = "framer-M3veg"

const variantClassNames = {dSwoN3Col: "framer-v-flej0s", i2VRM5KFs: "framer-v-1osn474", IFurfEriY: "framer-v-18het40", JTogpg1yy: "framer-v-1iw48iz", l8r4qk4au: "framer-v-1qriyuw", lHf0A5EYn: "framer-v-631t8v", PhmhcHCGo: "framer-v-is7fes", XA7aVRigW: "framer-v-3pyfmy", YW9NwFxdL: "framer-v-l965j7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, YW9NwFxdL: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "IFurfEriY", "Image 16:9": "YW9NwFxdL", "Image 3:2": "JTogpg1yy", "Image 4:3": "PhmhcHCGo", "Image 4:5": "i2VRM5KFs", "Variant 7": "dSwoN3Col", "Variant 8": "l8r4qk4au", "Variant 9": "lHf0A5EYn", Default: "XA7aVRigW"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XA7aVRigW", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XA7aVRigW", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-3pyfmy", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"XA7aVRigW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({dSwoN3Col: {"data-framer-name": "Variant 7"}, i2VRM5KFs: {"data-framer-name": "Image 4:5"}, IFurfEriY: {"data-framer-name": "Image 1:1"}, JTogpg1yy: {"data-framer-name": "Image 3:2"}, l8r4qk4au: {"data-framer-name": "Variant 8"}, lHf0A5EYn: {"data-framer-name": "Variant 9"}, PhmhcHCGo: {"data-framer-name": "Image 4:3"}, YW9NwFxdL: {"data-framer-name": "Image 16:9"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-M3veg[data-border=\"true\"]::after, .framer-M3veg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M3veg.framer-1bk2wnz, .framer-M3veg .framer-1bk2wnz { display: block; }", ".framer-M3veg.framer-3pyfmy { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-M3veg.framer-v-is7fes.framer-3pyfmy { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-M3veg.framer-v-1iw48iz.framer-3pyfmy { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-M3veg.framer-v-l965j7.framer-3pyfmy { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-M3veg.framer-v-1osn474.framer-3pyfmy { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-M3veg.framer-v-18het40.framer-3pyfmy { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-M3veg.framer-v-flej0s.framer-3pyfmy { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-M3veg.framer-v-1qriyuw.framer-3pyfmy, .framer-M3veg.framer-v-631t8v.framer-3pyfmy { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"PhmhcHCGo":{"layout":["fixed","fixed"]},"JTogpg1yy":{"layout":["fixed","fixed"]},"YW9NwFxdL":{"layout":["fixed","fixed"]},"i2VRM5KFs":{"layout":["fixed","fixed"]},"IFurfEriY":{"layout":["fixed","fixed"]},"dSwoN3Col":{"layout":["fixed","fixed"]},"l8r4qk4au":{"layout":["fixed","fixed"]},"lHf0A5EYn":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerZUCLBzDt6: React.ComponentType<Props> = withCSS(Component, css, "framer-M3veg") as typeof Component;
export default FramerZUCLBzDt6;

FramerZUCLBzDt6.displayName = "Image / Aspect Ratio Copy 4";

FramerZUCLBzDt6.defaultProps = {height: 256, width: 256};

addPropertyControls(FramerZUCLBzDt6, {variant: {options: ["XA7aVRigW", "PhmhcHCGo", "JTogpg1yy", "YW9NwFxdL", "i2VRM5KFs", "IFurfEriY", "dSwoN3Col", "l8r4qk4au", "lHf0A5EYn"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerZUCLBzDt6, [])